
import { defineComponent, Ref, ref, onMounted, onBeforeMount, DefineComponent, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Api from "@/services/Api";
import { Modal } from "bootstrap";
import EditStore from "@/views/store/EditStore.vue";
import useEmitter from "@/composables/Emmiter";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { useRouter } from "vue-router";
import { useLoaderStore } from "@/store/LoaderStore";
import { useOpenCreateStore } from "@/store/OpenCreateStore";
import { useGetIntegracaoParceirosStore } from "@/store/GetIntegracaoParceirosStore";
import useAlert from "@/composables/Alert";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import Pagination from "@/components/Pagination.vue";
import store from "@/store";

interface Store {
  finalPlano: string;
  id: number;
  inicioPlano: string;
  nome: string;
  grupo: string;
  plano: string;
  progress: number;
  status: number | boolean;
  fixed: boolean;
  cnpj?: any;
}

interface StoreFilter {
  nomeFantasia: string;
  cnpj: string;
  endereco: string;
  grupo: string;
  data: any;
  status: string;
  fixed?: boolean;
  id?:any;
}

interface DadosLoja {
  id: number;
  CNPJ: string;
  telefone: string;
  responsavel: string;
  nomefantasia: string;
  razaoSocial: string;
  endereco: string;
  rede: string;
  email: string;
  codGrupo?: number;
  created_at: string
}

interface Marcas {
  id: number;
  oleos: any[];
  filtroAr: any[];
  filtroCabine: any[];
  filtroCombustivel: any[];
  filtroOleo: any[];
  pneus: any[];
}

interface Implantacao {
  id: number;
  idConsultor: number;
  nomeConsultor: any;
  arrayConsultor: any[];
  arrayErpsLoja: any[];
  checklistEntrada: any[];
  checklistInspecao: any[];
  automacao: number | null;
  nomeAutomacao: any;
  dataImplantacao: any;
  previsaoImplantacao: any;
  observacoesGerais: any;
  usaChecklist: number;
  checklistAdicional: number;
}

interface ConfigLoja {
  id: number;
  produtosPrincipal: any[];
  tipoApp: number;
  layoutApp: any[];
  tipoOferta: string;
  textoLegal: string;
  assinatura: string;
  rodapeOrcamento: string;
  buscaPlaca: any;
  fornecedorBuscaPlaca?: string;
  alterarValor: any;
  formRecepcao: any;
  valorMO: any;
  oleoFracionado: any;
  podeDesconto: any;
  assinaturaCliente: any;
  estoqueNegativo: any;
  maximoDesconto: number | string;
}

interface DadosExtrasIpiranga {
  dadosExtras: {
    geral: any;
    credenciais: any;
  };
}

interface EditStore {}

export default defineComponent({
  name: "Store",

  components: {
    Pagination,
    Title,
    Button,
    EditStore: EditStore as unknown as DefineComponent<{}, {}, EditStore>,
  },

  setup() {
    let auxModal;
    const emitter = useEmitter();
    const router = useRouter();
    const loaderStore = useLoaderStore();
    const openCreateStore = useOpenCreateStore();
    const getIntegracaoParceirosStore = useGetIntegracaoParceirosStore();
    const { showTimeAlert } = useAlert();
    const filhoRef = ref<InstanceType<typeof Pagination> | null>(null);

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    const getUrlParam = (param: string) => new URLSearchParams(window.location.search).get(param);
    // input 'Tue Jan 03 2023 00:00:00 GMT-0300 (Brasilia Standard Time)'
    // output '2023-01-03 03:00:00'
    const specificFormatData = (date: any) => new Date(date).toISOString().substr(0, 19).replace("T", " ");

    const total: Ref<number> = ref(0);
    const page: Ref<number | null> = ref(null);
    const maxLimitByPage: Ref<number> = ref(10);
    const loadStores: Ref<boolean> = ref(false);
    const exibirIntegracao: Ref<boolean> = ref(false);
    const storeFilter: Ref<StoreFilter> = ref({
      nomeFantasia: "",
      cnpj: "",
      endereco: "",
      grupo: "",
      data: "",
      status: "",
    });
    const storeGroups: Ref<any[]> = ref([]);
    const createStore: Ref<boolean> = ref(false);
    const nomeLoja: Ref<string> = ref("");
    const arrayConsultores: Ref<any[]> = ref([]);
    const exibirEditar: Ref<boolean> = ref(true);
    const pagina: Ref<number> = ref(1);

    const dadosLoja: Ref<DadosLoja> = ref({
      id: 0,
      CNPJ: "",
      telefone: "",
      responsavel: "",
      nomefantasia: "",
      razaoSocial: "",
      endereco: "",
      rede: "",
      email: "",
      created_at: ""
    });

    const marcasParceiras: Ref<Marcas> = ref({
      id: 0,
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
    });

    const implantacao: Ref<Implantacao> = ref({
      id: 0,
      idConsultor: 0,
      nomeConsultor: "",
      arrayConsultor: [],
      arrayErpsLoja: [],
      checklistEntrada: [],
      checklistInspecao: [],
      automacao: null,
      usaChecklist: 0,
      checklistAdicional: 0,
      nomeAutomacao: "",
      dataImplantacao: null,
      previsaoImplantacao: null,
      observacoesGerais: null,
    });

    const configLoja: Ref<ConfigLoja> = ref({
      id: 0,
      produtosPrincipal: [],
      tipoApp: 0,
      layoutApp: [],
      tipoOferta: "",
      textoLegal: "",
      assinatura: "",
      rodapeOrcamento: "",
      buscaPlaca: "",
      fornecedorBuscaPlaca: "",
      alterarValor: "",
      formRecepcao: "",
      valorMO: "",
      oleoFracionado: "",
      podeDesconto: "",
      assinaturaCliente: "",
      estoqueNegativo: "",
      maximoDesconto: 0,
    });
    const dadosExtraRef: Ref<DadosExtrasIpiranga> = ref({
      dadosExtras: {
        geral: null,
        credenciais: null,
      },
    });
    const storeArray: Ref<Store[]> = ref([]);
    const plan: Ref<number> = ref(100);
    const fastFind: Ref<string> = ref('');
    const activePin: Ref<boolean> = ref(false)
    const hoveredCard: Ref<any> = ref(null)

    const progressBarLogic = (begin: string, final: string) =>
      Math.round(((new Date(final).getTime() - Date.now()) / (new Date(final).getTime() - new Date(begin).getTime())) * 100);
    const percentProgress = (begin: string, final: string) => (progressBarLogic(begin, final) < 0 ? 0 : progressBarLogic(begin, final));

    async function handlePage(paramPage: number){
      await getStores(paramPage, maxLimitByPage.value);
    } 

    async function getStores(pageParam: number, limit: number, search = false) {
      loadStores.value = true;
      const lojaLocalStorage = localStorage.getItem('lojaFixada')
      const lojaSelecionada = JSON.parse(lojaLocalStorage as any)

      if (storeFilter.value.data) {
        storeFilter.value.data[0] = specificFormatData(storeFilter.value.data[0]);
        storeFilter.value.data[1] = specificFormatData(storeFilter.value.data[1]);
      }
      fastFind.value || lojaSelecionada?.fixed ? loaderStore.close() : loaderStore.open();
      // const { data } = await Api.get("getStoresByPage", { page: pageParam, limit, ...storeFilter.value });

      const { data } = await Api.get("getStoresByPage", { page: pageParam, limit, ...storeFilter.value });

      total.value = data.total;
      page.value = pageParam;

      // if (!search) storeArray.value = [...storeArray.value, ...data.lojas];
      storeArray.value = data.lojas;

      storeArray.value.forEach(store => {
        store.status = store.status && true;
        store.progress = percentProgress(store.inicioPlano, store.finalPlano);
        store.fixed = false
        if(lojaSelecionada){
          if(store.id == lojaSelecionada.id) {
            store.fixed = lojaSelecionada.fixed ? true : false
          }
        }
      });
      loaderStore.close();
      loadStores.value = false;      
    }

    function toggleScreenEdit() {
      exibirEditar.value = !exibirEditar.value;
    }

    async function resetObject(obj) {
      for (const prop in obj) {
        obj[prop] = typeof obj[prop] === "object" ? {} : "";
      }
    }

    async function newLojaCreate() {
      resetObject(dadosLoja.value);
      resetObject(marcasParceiras.value);
      resetObject(implantacao.value);
      resetObject(configLoja.value);
      resetObject(dadosExtraRef.value);

      openCreateStore.open();
      exibirEditar.value = !exibirEditar.value;
    }
    async function getInfoLojas(id: number) {
      toggleScreenEdit();
      try {
        loaderStore.open();
        const { data } = await Api.get(`getLoja?idLoja=${id}`);
        const {
          loja,
          FiltroDeAr,
          FiltroDeArCondicionado,
          FiltroDeCombustivel,
          FiltroDeOleo,
          oleoDoMotor,
          pneu,
          listaChecklist,
          listaChecklistAdicional,
          consultores,
          arrayErp,
          produtosPrincipais,
          bandeiras,
          maximoDesconto
        } = data;

        arrayConsultores.value = consultores;

        dadosLoja.value = {
          id: id,
          CNPJ: loja.cnpj,
          telefone: loja.telefone,
          responsavel: loja.responsavel,
          nomefantasia: loja.nomeFantasia,
          razaoSocial: loja.razaoSocial,
          endereco: loja.endereco,
          rede: loja.rede,
          email: loja.email,
          codGrupo: loja.codGrupo,
          created_at: loja.created_at
        };
        marcasParceiras.value = {
          id: id,
          oleos: oleoDoMotor,
          filtroAr: FiltroDeAr,
          filtroCabine: FiltroDeArCondicionado,
          filtroCombustivel: FiltroDeCombustivel,
          filtroOleo: FiltroDeOleo,
          pneus: pneu,
        };
        implantacao.value = {
          id: id,
          idConsultor: loja.idConsultor,
          nomeConsultor: arrayConsultores.value.find(nome => nome.id === loja.idConsultor),
          arrayConsultor: consultores,
          arrayErpsLoja: arrayErp,
          checklistEntrada: listaChecklist,
          checklistInspecao: listaChecklistAdicional,
          automacao: loja.idErp,
          nomeAutomacao: arrayErp.find(marca => marca.id === loja.idErp),
          dataImplantacao: loja.dataImplantacao,
          previsaoImplantacao: loja.previsaoImplantacao,
          observacoesGerais: loja.observacoesGerais,
          usaChecklist: loja.usaChecklist,
          checklistAdicional: loja.checklistAdicional,
        };
        configLoja.value = {
          id: 0,
          produtosPrincipal: produtosPrincipais,
          layoutApp: bandeiras,
          tipoApp: loja.tipoApp,
          tipoOferta: loja.tipoOferta,
          textoLegal: loja.textoLegalALert,
          assinatura: loja.textoLegalAssinatura,
          rodapeOrcamento: loja.textoRodaPeOrcamento,
          buscaPlaca: loja.buscaPlaca,
          fornecedorBuscaPlaca: loja.fornecedorBuscaPlaca,
          alterarValor: loja.alterarValor,
          formRecepcao: loja.formularioDeRecepcao,
          valorMO: loja.utilizaMO,
          oleoFracionado: loja.oleoFracionado,
          podeDesconto: loja.podeDesconto,
          assinaturaCliente: loja.assinaturaDoCliente,
          estoqueNegativo: loja.vendaEstoqueNegativo,
          maximoDesconto: loja.maximoDesconto
        };
        dadosExtraRef.value = {
          dadosExtras: JSON.parse(loja.dadosExtras),
        };

        const { nomefantasia } = dadosLoja.value;
        nomeLoja.value = nomefantasia;
        enviarEmit();
      } catch (error) {
        console.log(error);
      } finally {
        dadosExtraRef.value.dadosExtras ? (exibirIntegracao.value = true) : (exibirIntegracao.value = false);
        enviarExibicaoJetOilIntegracao();
        loaderStore.close();
      }
    }
    //arrayErps.value.filter((marca) => marca.id === loja.idConsultor ? marca.nome : null)
    async function enviarEmit() {
      await emitter.emit("GetDadosLoja", {
        DadosLoja: [dadosLoja.value, marcasParceiras.value, implantacao.value, configLoja.value, dadosExtraRef.value],
      });
    }

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    async function getIntegracaoIpiranga() {
      loaderStore.open();
      try {
        if (!getIntegracaoParceirosStore.status.split(",")[1] || getIntegracaoParceirosStore.status.split(",")[1] === ' '){
          return loaderStore.close();
        }
        
        const res = await Api.get(`getPdvIntegracao?tipoIntegracao=conecta&codGrupo=${getIntegracaoParceirosStore.status.split(",")[0]}&cnpjIntegracao=${getIntegracaoParceirosStore.status.split(",")[1].replace(/[^\d]/g, "")}`);

        if (res.pdv && !res.pdv.dadosExtras) {
          showTimeAlert('CNPJ não possui integração(dados extras)', 'error'); 
          dadosLoja.value = {
              ...dadosLoja.value,
              CNPJ: getIntegracaoParceirosStore.status.split(",")[1],
          };
          enviarEmit();
          return loaderStore.close();
        }

        const newDadosExtras = JSON.parse(res.pdv.dadosExtras ?? "{}");

        if (newDadosExtras) {
          if (!dadosExtraRef.value.dadosExtras) {
            dadosExtraRef.value.dadosExtras = {} as any;
          }

          dadosExtraRef.value.dadosExtras.geral = newDadosExtras.geral;
          dadosExtraRef.value.dadosExtras.credenciais = newDadosExtras.credenciais;
          dadosLoja.value = {
            ...dadosLoja.value,
            CNPJ: getIntegracaoParceirosStore.status.split(",")[1],
            nomefantasia : res.pdv.nomeFantasia,
            razaoSocial : res.pdv.razaoSocial,
            telefone: res.pdv.telefone,
            responsavel: res.pdv.responsavel,
            endereco: res.pdv.endereco,
            email: res.pdv.email,
          };


          exibirIntegracao.value = true;
          showTimeAlert('Integração atualizada(dados extras)', 'success'); 
        } else {
          exibirIntegracao.value = false;
        }
      } catch (error) {
        console.log(error);
      }
      enviarEmit();
      enviarExibicaoJetOilIntegracao();
      loaderStore.close();
    }

    function redirectToStore(str: string, id?: number): void {
      router.push({ path: `/lojas/${str}`, query: { storeId: id ? id : idFixado && idFixado.value} });
    }

    emitter.on("FilterStore", async params => {
      storeFilter.value = params.FiltroLojas;
      getStores(1, maxLimitByPage.value, true);
      if (filhoRef.value) {
        filhoRef.value.resetPage(1);
      } 
    });

    async function enviarExibicaoJetOilIntegracao() {
      await emitter.emit("exibir", {
        integracao: exibirIntegracao.value,
      });
    }

    async function getAllNewStore(str: string) {
      if (Number(str) === 0) return;
      let lojasGerais, checklistsGerais, lojaIntegrada;

      try {
        lojaIntegrada = await Api.get(`loja/grupo?idLoja=${str}`);
        const { loja } = lojaIntegrada;

        const { assinaturaDoCliente } = loja;
        configLoja.value = { ...loja, assinaturaCliente: assinaturaDoCliente };
        const {
          FiltroDeAr,
          FiltroDeArCondicionado,
          FiltroDeCombustivel,
          FiltroDeOleo,
          oleoDoMotor,
          pneu,
          arrayErp,
          produtosPrincipais,
          bandeiras,
          listaChecklist,
          listaChecklistAdicional,
        } = lojaIntegrada;

        dadosLoja.value = {
          id: 0,
          CNPJ: loja.cnpj,
          telefone: loja.telefone,
          responsavel: loja.responsavel,
          nomefantasia: loja.nomeFantasia,
          razaoSocial: loja.razaoSocial,
          endereco: loja.endereco,
          rede: loja.rede,
          email: loja.email,
          created_at: loja.created_at
        };
        marcasParceiras.value = {
          id: 0,
          oleos: oleoDoMotor,
          filtroAr: FiltroDeAr,
          filtroCabine: FiltroDeArCondicionado,
          filtroCombustivel: FiltroDeCombustivel,
          filtroOleo: FiltroDeOleo,
          pneus: pneu,
        };
        implantacao.value = {
          id: 0,
          idConsultor: loja.idConsultor,
          nomeConsultor: arrayConsultores.value.find(nome => nome.id === loja.idConsultor),
          arrayConsultor: [],
          arrayErpsLoja: arrayErp,
          checklistEntrada: listaChecklist,
          checklistInspecao: listaChecklistAdicional,
          automacao: loja.idErp,
          nomeAutomacao: arrayErp.find(marca => marca.id === loja.idErp),
          dataImplantacao: loja.dataImplantacao,
          previsaoImplantacao: loja.previsaoImplantacao,
          observacoesGerais: loja.observacoesGerais,
          usaChecklist: loja.usaChecklist,
          checklistAdicional: loja.checklistAdicional,
        };
        configLoja.value = {
          id: 0,
          produtosPrincipal: produtosPrincipais,
          layoutApp: bandeiras,
          tipoApp: loja.tipoApp,
          tipoOferta: String(loja.tipoOferta),
          textoLegal: loja.textoLegalALert,
          assinatura: loja.textoLegalAssinatura,
          rodapeOrcamento: loja.textoRodaPeOrcamento,
          buscaPlaca: loja.buscaPlaca,
          alterarValor: loja.alterarValor,
          formRecepcao: loja.formularioDeRecepcao,
          valorMO: loja.utilizaMO,
          oleoFracionado: loja.oleoFracionado,
          podeDesconto: loja.podeDesconto,
          assinaturaCliente: loja.assinaturaDoCliente,
          estoqueNegativo: loja.vendaEstoqueNegativo,
          maximoDesconto: loja.maximoDesconto
        };
      } catch (error) {
        console.log(error);
      }

      if (!lojaIntegrada) {
        resetObject(dadosLoja.value);
        resetObject(marcasParceiras.value);
        resetObject(implantacao.value);
        resetObject(configLoja.value);
        resetObject(dadosExtraRef.value);
        lojasGerais = await Api.get(`verificaIntegracoesGrupo?idGrupo=${str}`);

        checklistsGerais = await Api.get(`lojas/checklists?idGrupo=${str}`);

        const { permisaoChecklist, listaChecklist, permisaoChecklistAdicional, listaChecklistAdicional } = checklistsGerais;
        
        dadosLoja.value = {
          id: 0,
          CNPJ: '',
          telefone: '',
          responsavel: lojasGerais.grupo.responsavel,
          nomefantasia: '',
          razaoSocial: '',
          endereco: '',
          rede: '',
          email: '',
          created_at: ""
        };
        // marcasParceiras.value = {
        //   id: 0,
        //   oleos: oleoDoMotor,
        //   filtroAr: FiltroDeAr,
        //   filtroCabine: FiltroDeArCondicionado,
        //   filtroCombustivel: FiltroDeCombustivel,
        //   filtroOleo: FiltroDeOleo,
        //   pneus: pneu,
        // };
        implantacao.value = {
          id: 0,
          idConsultor: 0,
          nomeConsultor: '',
          arrayConsultor: [],
          arrayErpsLoja: [],
          checklistEntrada: listaChecklist,
          checklistInspecao: listaChecklistAdicional,
          automacao: null,
          nomeAutomacao: '',
          dataImplantacao: '',
          previsaoImplantacao: '',
          observacoesGerais: '',
          usaChecklist: permisaoChecklist,
          checklistAdicional: permisaoChecklistAdicional,
        };
        configLoja.value = {
          id: 0,
          produtosPrincipal: [],
          layoutApp: [],
          tipoApp: lojasGerais.grupo.tipoApp,
          tipoOferta: lojasGerais.grupotipoOferta,
          textoLegal: '',
          assinatura: '',
          rodapeOrcamento: '',
          buscaPlaca: lojasGerais.grupo.buscaPlaca,
          alterarValor: lojasGerais.grupo.alterarValor,
          formRecepcao: lojasGerais.grupo.formularioDeRecepcao,
          valorMO: lojasGerais.grupo.utilizaMO,
          oleoFracionado: lojasGerais.grupo.oleoFracionado,
          podeDesconto: lojasGerais.grupo.podeDesconto,
          assinaturaCliente: lojasGerais.grupo.assinaturaDoCliente,
          estoqueNegativo: lojasGerais.grupo.vendaEstoqueNegativo,
          maximoDesconto: 0
        };
      }
      enviarEmit();
    }

    async function getAllParceiros() {
      const res = await Api.get(`getAllParceiros`);
    }

    function fixStore(){
      activePin.value = !activePin.value
    }

    function changeFixed(id: any){
      const store = storeArray.value.find(item => item.id === id);
      if (store) {
        store.fixed = !store.fixed;
        localStorage.setItem('lojaFixada',JSON.stringify(storeFilter.value));
      }
      if (store?.fixed){
        storeFilter.value = {
          ...storeFilter.value, 
          nomeFantasia: store.nome, 
          fixed: store.fixed,
          id: store.id
        };
        localStorage.setItem('lojaFixada',JSON.stringify(storeFilter.value));
        localStorage.removeItem('lojaSelecionada')
        getStores(1, maxLimitByPage.value, true); 
      } else {
        resetObject(storeFilter.value)
        localStorage.removeItem('lojaFixada')
        getStores(1, maxLimitByPage.value, true);
      }
    }

    function alteraStatusLoja(loja){
      try {
        loaderStore.open();
        Api.post("atualizaStatusLoja", { ...loja} ).then( data => {
          loaderStore.close();
        });
      } catch (error) {
        loaderStore.close();
      }


    }

    watch(
      () => exibirIntegracao.value,
      () => enviarExibicaoJetOilIntegracao()
    );

    watch(
      () => fastFind.value,
      () => {
        if(fastFind.value.length > 2 || !fastFind.value){
          storeFilter.value.nomeFantasia = fastFind.value
          getStores(1, maxLimitByPage.value, true);          
        }
        if(!storeArray.value.length){
          //inserir busca futura por outros itens          
        }
        if (filhoRef.value) {
        filhoRef.value.resetPage(1);
      } 
      } 
    );

    watch(
      () => getIntegracaoParceirosStore.status,
      () => {
        getAllNewStore(getIntegracaoParceirosStore.status.split(",")[0]);
        getIntegracaoIpiranga();
      }
    );

    function atualizaGrade() {
      getStores(1, maxLimitByPage.value, true);
    }

    onBeforeMount(async () => {
      // await handlePage(1);
      await getStoreGroups();
      getAllParceiros();
    });

    const idFixado: Ref<any> = ref(null)

    onMounted(async () => {
      const { id } = JSON.parse(localStorage.getItem('lojaFixada') || '{}');
      idFixado.value = id
      // const dadosDePesquisaViaFiltro = localStorage.getItem('lojaSelecionada')
      const dadosDePesquisaViaLojaFixada = localStorage.getItem('lojaFixada')
      // if(dadosDePesquisaViaFiltro){
      //   storeFilter.value = JSON.parse(dadosDePesquisaViaFiltro)
      //   return getStores(1, maxLimitByPage, true);          
      // }
      if(dadosDePesquisaViaLojaFixada){
        storeFilter.value = JSON.parse(dadosDePesquisaViaLojaFixada)
        return getStores(1, maxLimitByPage.value, true);          
      }
      await handlePage(pagina.value);
      // so utiliza a logica de carregar mais conteudo dando scroll pagina se o componente utilizado no observer existir no dom
      // if (!!document.querySelector("#loadNextPage")) {
      //   const observer = new IntersectionObserver(entries => {
      //     if (entries[0].isIntersecting) handlePage((pagina.value = pagina.value + 1));
      //   }).observe(document.querySelector("#loadNextPage") as any);
      // }
    });

    return {
      atualizaGrade,
      page,
      maxLimitByPage,
      plan,
      storeArray,
      storeFilter,
      loadStores,
      handlePage,
      getStores,
      redirectToStore,
      console,
      openModal,
      ElConfigProvider,
      ptBr,
      getInfoLojas,
      dadosLoja,
      nomeLoja,
      exibirIntegracao,
      exibirEditar,
      toggleScreenEdit,
      newLojaCreate,
      router,
      fastFind,
      fixStore,
      activePin,
      changeFixed,
      hoveredCard,
      alteraStatusLoja,
      total,
      filhoRef
    };
  },
});
