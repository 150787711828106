
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { DefineComponent, defineComponent, onMounted, ref, Ref, watch } from "vue";
import ConfigLojas from "./components/ConfigLojas.vue";
import DadosDaImplantacao from "./components/DadosDaImplantacao.vue";
import DadosDaLoja from "./components/DadosDaLoja.vue";
import MarcaPadrao from "./components/MarcaPadrao.vue";
import Button from "@/components/Button.vue";
import DadosExtrasIpiranga from "./components/DadosExtrasIpiranga.vue";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";
import { useGetIntegracaoParceirosStore } from "@/store/GetIntegracaoParceirosStore";
import { useOpenCreateStore } from "@/store/OpenCreateStore";

interface ModalDadosDaLoja {}
interface ModalMarcaPadrao {}
interface ModalDadosDaImplantacao {}
interface ModalConfigLojas {}
interface DadosExtrasIpiranga {}
interface DadosExtras {
  geral: any;
  credenciais: any;
}

interface LojaCompletaAtt {
  id: number | null;
  cnpj: string;
  telefone: string;
  responsavel: string;
  nomeFantasia: string;
  razaoSocial: string;
  endereco: string;
  rede: string;
  email: string;
  oleos: any[];
  filtroAr: any[];
  filtroCabine: any[];
  filtroCombustivel: any[];
  filtroOleo: any[];
  pneus: any[];
  idErp: number;
  checklistEntrada: null;
  checklistInspecao: null;
  dataImplantacao: null;
  previsaoImplantacao: any;
  observacoesGerais: any;
  idConsultor: number;
  produtosPrincipal: number;
  tipoApp: number;
  tipoOferta: string;
  textoLegalALert: string;
  textoLegalAssinatura: string;
  textoRodaPeOrcamento: string;
  buscaPlaca: string;
  fornecedorBuscaPlaca: string;
  alterarValor: string;
  formularioDeRecepcao: string;
  utilizaMO: string;
  oleoFracionado: string;
  podeDesconto: string;
  maximoDesconto: string;
  assinaturaDoCliente: string;
  vendaEstoqueNegativo: string;
  dadosExtras: DadosExtras;
}

export default defineComponent({
  components: {
    DadosDaLoja: DadosDaLoja as unknown as DefineComponent<{}, {}, ModalDadosDaLoja>,
    MarcaPadrao: MarcaPadrao as unknown as DefineComponent<{}, {}, ModalMarcaPadrao>,
    DadosDaImplantacao: DadosDaImplantacao as unknown as DefineComponent<{}, {}, ModalDadosDaImplantacao>,
    ConfigLojas: ConfigLojas as unknown as DefineComponent<{}, {}, ModalConfigLojas>,
    DadosExtrasIpiranga: DadosExtrasIpiranga as unknown as DefineComponent<{}, {}, DadosExtrasIpiranga>,
    Button,
  },
  name: "EditStore",
  emits: ["updateGradeLojas", "toggleScreenEdit"],
  props: { nomeLojaDinamico: String },
  setup(props, { emit }) {
    const emitter = useEmitter();
    const { showTimeAlert } = useAlert();
    const loaderStore = useLoaderStore();
    const loadingSalvar: Ref<boolean> = ref(false)
    const storeGroups: Ref<any[]> = ref([]);
    const getIntegracaoParceirosStore = useGetIntegracaoParceirosStore()
    const getUseOpenCreateStore = useOpenCreateStore()
    const planoSelected: Ref<string> = ref('');
    const dadosParaNovaLoja: Ref<any> = ref();

    const lojaCompletaAtt: Ref<LojaCompletaAtt> = ref({
      id: null,
      cnpj: "",
      telefone: "",
      responsavel: "",
      nomeFantasia: "",
      razaoSocial: "",
      endereco: "",
      rede: "",
      email: "",
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
      idErp: 0,
      checklistEntrada: null,
      checklistInspecao: null,
      dataImplantacao: null,
      previsaoImplantacao: null,
      observacoesGerais: null,
      idConsultor: 0,
      produtosPrincipal: 0,
      tipoApp: 0,
      tipoOferta: "",
      textoLegalALert: "",
      textoLegalAssinatura: "",
      textoRodaPeOrcamento: "",
      buscaPlaca: "",
      fornecedorBuscaPlaca: "",
      alterarValor: "",
      formularioDeRecepcao: "",
      utilizaMO: "",
      oleoFracionado: "",
      podeDesconto: "",
      maximoDesconto: "",
      assinaturaDoCliente: "",
      vendaEstoqueNegativo: "",
      dadosExtras: {
        geral: null,
        credenciais: null,
      },
    });

    emitter.on("GetDadosLojaAtualizados", async params => {
      const { id, responsavel, telefone, CNPJ, nomefantasia, razaoSocial, endereco, rede, email } = params.DadosLojaAtualizados[0];
      lojaCompletaAtt.value = {
        ...lojaCompletaAtt.value,
        id,
        responsavel,
        telefone,
        cnpj: CNPJ,
        nomeFantasia: nomefantasia,
        razaoSocial,
        endereco,
        rede,
        email,
      };
    });
    emitter.on("GetMarcaPadraoAtualizados", async params => {
      const { filtroAr, filtroCabine, filtroCombustivel, filtroOleo, oleos, pneus } = params.MarcaPadraoAtualizados[0];
      lojaCompletaAtt.value = { ...lojaCompletaAtt.value, filtroAr, filtroCabine, filtroCombustivel, filtroOleo, oleos, pneus };
    });
    emitter.on("GetImplantacaoAtualizado", async params => {
      const { ErpLoja, checklistEntrada, checklistInspecao, dataImplantacao, previsaoImplantacao, observacoesGerais, idConsultor } = params.ImplantacaoAtualizado[0];
      planoSelected.value = params.planoSelected
      lojaCompletaAtt.value = { ...lojaCompletaAtt.value, idErp: ErpLoja, checklistEntrada, checklistInspecao, dataImplantacao, previsaoImplantacao, observacoesGerais, idConsultor };
    });
    emitter.on("GetLojaAtualizada", async params => {
      const {
        alterarValor,
        assinatura,
        assinaturaCliente,
        buscaPlaca,
        estoqueNegativo,
        formRecepcao,
        oleoFracionado,
        podeDesconto,
        rodapeOrcamento,
        textoLegal,
        tipoApp,
        tipoOferta,
        valorMO,
        produtosPrincipal,
        fornecedorBuscaPlaca,
        maximoDesconto
      } = params.ConfigLojaAtualizada[0];
      lojaCompletaAtt.value = {
        ...lojaCompletaAtt.value,
        alterarValor,
        textoLegalAssinatura: assinatura,
        assinaturaDoCliente: assinaturaCliente,
        buscaPlaca,
        fornecedorBuscaPlaca,
        vendaEstoqueNegativo: estoqueNegativo,
        formularioDeRecepcao: formRecepcao,
        oleoFracionado,
        podeDesconto,
        maximoDesconto,
        textoRodaPeOrcamento: rodapeOrcamento,
        textoLegalALert: textoLegal,
        tipoApp,
        tipoOferta,
        utilizaMO: valorMO,
        produtosPrincipal: produtosPrincipal.tipoCardapio,
      };
    });

    emitter.on("GetDadosExtrasAtualizados", async params => {
      const { dadosExtras } = params.DadosExtrasAtualizados[0];
      lojaCompletaAtt.value = { ...lojaCompletaAtt.value, dadosExtras };
    });

    async function sendUpdateInfos() {
      loadingSalvar.value = true
      loaderStore.open();

      const { oleos, filtroAr, filtroCabine, filtroCombustivel, filtroOleo, pneus, id, ...loja } = lojaCompletaAtt.value;
      
      const lojaCompleta = { 
        id: id == 0 ? null : id,
        codGrupo: getIntegracaoParceirosStore.status.split(",")[0] ? getIntegracaoParceirosStore.status.split(",")[0] : null, 
        ...loja 
        };
      const {codGrupo, cnpj, nomeFantasia, razaoSocial, produtosPrincipal, tipoApp, tipoOferta} = lojaCompleta

      if(getUseOpenCreateStore.status){
        if (!codGrupo || !cnpj || !nomeFantasia || !razaoSocial || !produtosPrincipal || !tipoOferta){
          closeLoadingsSaveLoja()
          return showTimeAlert('Preencha os campos obrigatórios', "error");
        }
        if (await getCnpjOk(cnpj)) {
          closeLoadingsSaveLoja()
          return showTimeAlert('CNPJ ja cadastrado', "error");
        }
      }

      
      if (cnpj.length < 14) {
        closeLoadingsSaveLoja()
        return showTimeAlert('CNPJ inválido', "error");
      }

      const bodyParceiros = {
        idLoja: id,
        parceiros: {
          oleo: oleos,
          filtroAr,
          filtroAc: filtroCabine,
          filtroCombustivel,
          filtroOleo,
          pneu: pneus,
        },
      };
      let plano;
      try {
        
        if(getIntegracaoParceirosStore.status) {
            plano = {selectPlano: planoSelected.value || 99}

            const {data} = await Api.post("salvaLoja", { ...lojaCompleta, ...plano });
            
            const bodyParceirosLojaNova = {
              idLoja: data.loja.codConcessionaria,
              parceiros: {
                oleo: oleos,
                filtroAr,
                filtroAc: filtroCabine,
                filtroCombustivel,
                filtroOleo,
                pneu: pneus,
              },
            };

            const res2 = await Api.post("salvaParceiros", { ...bodyParceirosLojaNova });
            // const res3 = await Api.post("salvaDadosExtras", { ...lojaCompleta });
        } else {
          const res1 = await Api.post("salvaLoja", { ...lojaCompleta });
          const res2 = await Api.post("salvaParceiros", { ...bodyParceiros });
          if (lojaCompleta.dadosExtras && lojaCompleta.dadosExtras.credenciais) {
            const { data } = await Api.post("salvaDadosExtras", { ...lojaCompleta });
          }
        }
        emit("updateGradeLojas");
        showTimeAlert(`${getUseOpenCreateStore.status ? 'Loja criada com sucesso' : 'Loja atualizada com sucesso'}`);
        //verifica se é criação de loja para redirecionar o usuario para tela de lojas
        if(!id) emit("toggleScreenEdit")
      } catch (error) {
        showTimeAlert("Não foi possivel criar loja", "error");
      } finally {
        closeLoadingsSaveLoja()
      }
    }
    function closeLoadingsSaveLoja(){
      loaderStore.close();
      loadingSalvar.value = false
    }

    async function getCnpjOk(cnpj: string) {
      let cnpj_sem_pontuacao = cnpj.replace(/[^\d]/g, "");
      const res = await Api.post("lojas/buscarCnpjExiste", { cnpj: cnpj_sem_pontuacao });
      return res.cnpjExiste;
    }
    async function buscaDadosParaNovaLoja(){
      try{
        const { data } = await Api.get('buscaDadosParaNovaLoja') 
        dadosParaNovaLoja.value = data
      }catch(error){
        console.log(error)
      }
    }

    onMounted(() => {
      buscaDadosParaNovaLoja();
    });
    return { sendUpdateInfos, getUseOpenCreateStore, loaderStore, getIntegracaoParceirosStore, loadingSalvar, dadosParaNovaLoja };
  },
});
