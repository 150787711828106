
interface Implantacao {
  id: number;
  idConsultor: number;
  nomeConsultor: any;
  arrayConsultor: any[];
  arrayErpsLoja: any[];
  checklistEntrada: any;
  checklistInspecao: any[];
  automacao: number | null;
  nomeAutomacao: any;
  dataImplantacao: any;
  previsaoImplantacao: any;
  observacoesGerais: any;
  usaChecklist: number;
  checklistAdicional: number;
}
interface ImplantacaoAtt {
  idConsultor: any;
  ErpLoja: any;
  checklistEntrada: any;
  checklistInspecao: any;
  dataImplantacao: any;
  previsaoImplantacao: any;
  observacoesGerais: any;
}

import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { useOpenCreateStore } from "@/store/OpenCreateStore";
import Button from "@/components/Button.vue";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";


export default defineComponent({
  name: "DadosDaImplantacao",
  emits: [],
  props: {
    dadosNovaLoja: Object
  },
  components: {
    Button,
  },
  setup(props) {
    const emitter = useEmitter();
    const openCreateStore = useOpenCreateStore();
    const { showTimeAlert } = useAlert();
    const nomeConsultorRef: Ref<any> = ref({});
    const isLoadingResetCache: Ref<boolean> = ref(false);
    const tipoChecklistEnt: Ref<any> = ref({});
    const tipoChecklistSaida: Ref<any> = ref({});
    const tipoAutomacao: Ref<any> = ref("");
    const consultorId: Ref<any> = ref("");
    const planoSelected: Ref<any> = ref([]);
    const planos: Ref<any> = ref([]);
    const cnpjPdv: Ref<string> = ref('');
    const LoaderStore = useLoaderStore();
    const implantacaoAtt: Ref<ImplantacaoAtt> = ref({
      idConsultor: null,
      ErpLoja: null,
      checklistEntrada: null,
      checklistInspecao: null,
      dataImplantacao: null,
      previsaoImplantacao: null,
      observacoesGerais: null,

    });
    const dadosfinais: Ref<ImplantacaoAtt> = ref({
      idConsultor: null,
      ErpLoja: null,
      checklistEntrada: null,
      checklistInspecao: null,
      dataImplantacao: null,
      previsaoImplantacao: null,
      observacoesGerais: null,
    });

    const implantacao: Ref<Implantacao> = ref({
      id: 0,
      idConsultor: 0,
      nomeConsultor: {},
      arrayConsultor: [],
      arrayErpsLoja: [],
      checklistEntrada: [],
      checklistInspecao: [],
      automacao: null,
      usaChecklist: 0,
      checklistAdicional: 0,
      nomeAutomacao: "",
      dataImplantacao: null,
      previsaoImplantacao: null,
      observacoesGerais: null,
    });

    async function dadosAtt() {

      implantacaoAtt.value.ErpLoja = implantacaoAtt.value.ErpLoja || null


      dadosfinais.value = {
        idConsultor: implantacaoAtt.value.idConsultor,
        ErpLoja: implantacaoAtt.value.ErpLoja,
        checklistEntrada: (await implantacaoAtt.value.checklistEntrada?.checklist) || 0,
        checklistInspecao: (await implantacaoAtt.value.checklistInspecao?.checklist) || 0,
        dataImplantacao: implantacaoAtt.value.dataImplantacao,
        previsaoImplantacao: implantacaoAtt.value.previsaoImplantacao,
        observacoesGerais: implantacaoAtt.value.observacoesGerais,
      };
      await emitter.emit("GetImplantacaoAtualizado", {
        ImplantacaoAtualizado: [dadosfinais.value],
        planoSelected: planoSelected.value,
      });
    }

    emitter.on("GetDadosLoja", async params => {
      cnpjPdv.value = params.DadosLoja[0].CNPJ;
      if (!params.DadosLoja[1].idConsultor) {
        implantacao.value = params.DadosLoja[2];

        if (Array.isArray(implantacao.value.checklistInspecao)) {
          tipoChecklistSaida.value = implantacao.value.checklistInspecao.find(item => item.checklist === implantacao.value.checklistAdicional);
        } else {
          tipoChecklistSaida.value = "Sem checklist";
        }

        if (Array.isArray(implantacao.value.checklistEntrada)) {
          tipoChecklistEnt.value = implantacao.value.checklistEntrada.find(item => item.checklist === implantacao.value.usaChecklist);
        } else {
          tipoChecklistEnt.value = "Sem checklist";
        }
      }
    });




    async function getPlanos() {
      const res = await Api.get("getPlanos");
      planos.value = res;
      planos.value = [...planos.value, { nome: "Parceiros Átria", valor: "0.00", ordem: "99" }];
      planoSelected.value = "99";

    }

    function getDadosParaNovaLoja() {
      if (props.dadosNovaLoja) {
        const { arrayErp, consultores } = props.dadosNovaLoja;

        arrayErp.unshift({ nome: 'Sem Automação', id: null })

        implantacao.value = { ...implantacao.value, arrayErpsLoja: arrayErp, arrayConsultor: consultores };
      }
    }

    async function resetCacheAtualizaEstoquePdv() {
      try {
        LoaderStore.open();
        const { error, message } = await Api.post("resetCacheAtualizaEstoquePdv", { cnpj: cnpjPdv.value });
        if (error) {
          showTimeAlert('Erro ao resetar cache', 'error')
          console.log(message)
        } else {
          showTimeAlert('Cache atualiza estoque resetado!', 'success')
        }
      } catch (error) {
        console.log("error", error);
        LoaderStore.close();
      }
      LoaderStore.close();
    }


    watch(
      () => props.dadosNovaLoja,
      () => {
        getDadosParaNovaLoja()
      }
    )

    watch(
      () => implantacao.value.arrayConsultor,
      () => {
        if (!implantacao.value.arrayConsultor.length) getPlanos();
        return null;
      }
    );

    watch(
      () => implantacao.value.nomeConsultor,
      () => {
        // nomeConsultorRef.value = implantacao.value.arrayConsultor.find(consultor => consultor.id === implantacao.value.nomeConsultor.id);
        // consultorId.value = implantacao.value.nomeConsultor.id;
        tipoAutomacao.value = implantacao.value.nomeAutomacao;
        implantacaoAtt.value.ErpLoja = implantacao.value.automacao;
      }
    );

    watch(
      () => implantacaoAtt.value.ErpLoja,
      () => {
        dadosAtt();
      }
    );

    watch(
      () => planoSelected.value,
      () => {
        dadosAtt();
      }
    );

    watch(
      () => implantacaoAtt.value.idConsultor,
      () => {
        dadosAtt();
      }
    );

    watch(
      () => implantacao.value.nomeConsultor,
      () => {
        implantacaoAtt.value.idConsultor = implantacao.value.nomeConsultor?.id;
      }
    );

    watch(
      () => [tipoChecklistEnt.value, tipoChecklistSaida.value],
      () => {
        (implantacaoAtt.value.checklistEntrada = tipoChecklistEnt.value), (implantacaoAtt.value.checklistInspecao = tipoChecklistSaida.value);
      }
    );

    watch(
      () => [
        implantacao.value.nomeConsultor,
        implantacao.value.dataImplantacao,
        implantacao.value.previsaoImplantacao,
        implantacao.value.observacoesGerais,
        implantacao.value.checklistEntrada,
        nomeConsultorRef.value,
        tipoChecklistEnt.value,
        tipoChecklistSaida.value,
        tipoAutomacao.value,
      ],
      () => {
        implantacaoAtt.value.dataImplantacao = implantacao.value.dataImplantacao;
        implantacaoAtt.value.previsaoImplantacao = implantacao.value.previsaoImplantacao;
        implantacaoAtt.value.observacoesGerais = implantacao.value.observacoesGerais;
        dadosAtt();
      }
    );
    onMounted(() => getPlanos());

    return {
      ElConfigProvider,
      ptBr,
      implantacao,
      tipoChecklistEnt,
      nomeConsultorRef,
      tipoChecklistSaida,
      tipoAutomacao,
      consultorId,
      implantacaoAtt,
      dadosAtt,
      openCreateStore,
      planos,
      planoSelected,
      resetCacheAtualizaEstoquePdv,
      isLoadingResetCache
    };
  },
});
